"use client";

import { usePathname } from "next/navigation";
import { useEffect } from "react";
import { IFormData } from "./(languageAgnostic)/(diagnostic)/api/error/route";
import ErrorMessageComponent from "./errorMessage";

export default function ErrorPage({ error }: { error: Error & { digest?: string } }) {
	// Determine the path this not-found is replacing
	const pathname = usePathname();

	// Log to Splunk
	useEffect(() => {
		async function logToSplunk() {
			const data: IFormData = {
				pathname: pathname,
				digest: error.digest,
			};

			await fetch("/api/error", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			});
		}
		void logToSplunk();
	}, [pathname, error]);

	return (
		<ErrorMessageComponent>
			<div>
				<span>
					{"Something went wrong? You "}
					<i>{"calf"}</i>
					{" to be kidding. "}
				</span>
				<span>{"We'll have it fixed in no time."}</span>
			</div>
		</ErrorMessageComponent>
	);
}
